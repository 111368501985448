import { render, staticRenderFns } from "./exchange-scheme.vue?vue&type=template&id=64ff8558&scoped=true&"
import script from "./exchange-scheme.vue?vue&type=script&lang=js&"
export * from "./exchange-scheme.vue?vue&type=script&lang=js&"
import style0 from "./exchange-scheme.vue?vue&type=style&index=0&id=64ff8558&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64ff8558",
  null
  
)

export default component.exports