<template>
  <div class="page">
    <titlebar :name="page_name" :is_custom_back="true" @goBackCustom="goBack" />
    <div class="list_title">
      <div>兑换类型</div>
      <div>兑换内容</div>
      <div>所需积分</div>
      <div>已兑换</div>
      <div>状态</div>
      <div>操作</div>
    </div>
    <div class="box_index" v-if="list.length > 0">
      <div class="list_index" v-for="(item, index) in list" :key="index">
        <div>{{item.type | ExchangeType}}</div>
        <div>{{item.coupon[0].name}}</div>
        <div>{{item.score}}</div>
        <div>{{item.num || 0}}</div>
        <div :class="{'color-e60012': item.status == 2}">{{item.status | ExchangeStatus}}</div>
        <div>
          <template v-if="shop.user_model == 1">
            <van-icon name="edit" size="3rem" @click="jumpToDetail(2, item)" />
            <van-icon name="delete-o" size="3rem" color="#ee0a24" @click="openDialog(item)" style="margin-left: 6rem" />
          </template>
          <template v-else>无权操作</template>
        </div>
      </div>
    </div>
    <div class="box_index" v-if="list.length === 0">
      <div class="mg-tp100 content">
        <div class="nullStyle">
          <p class="text-center ft-24 color-999 mg-tp30">暂无数据~</p>
        </div>
      </div>
    </div>
    <van-col span="24">
      <div class="bottom-con">
        <van-pagination v-model="page" :total-items="total" :items-per-page="limit" @change="pageChange" force-ellipses
          mode="simple" style="width: 30%;" />
        <van-button color="#1588F5" @click="jumpToDetail(1)" v-if="shop.user_model == 1" icon="add" class="r-s-add-btn">新增方案</van-button>
      </div>
    </van-col>

    <popupOfDialog v-if="showDialog" :type="dialogType" :title="dialogTitle" :message="dialogMsg" :data="dialogData"
      @closePopDialog="closePopDialog" @confirmPopDialog="confirmPopDialog"></popupOfDialog>
  </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
import { mapGetters } from 'vuex';
import popupOfDialog from "../component/popupOfDialog.vue";
export default {
  name: "recharge-scheme",
  components: { titlebar, popupOfDialog },
  async created() {
    this.getList();
  },
  data() {
    return {
      page_name: 'exchange-scheme',
      list: [], // 商品单位
      limit: 8,
      page: 1,
      total: 0,
      showDialog: false,
      dialogTitle: "",
      dialogMsg: "",
      dialogType: "",
      dialogData: {},
    }
  },
  filters: {
    ExchangeType(val) {
      const obj = {
        1: '优惠券'
      }
      return obj[val] || '';
    },
    ExchangeStatus(val) {
      const obj = {
        1: '启用',
        2: '禁用'
      }
      return obj[val] || '';
    },
  },
  computed: {
    ...mapGetters({ shop: 'shop' })
  },
  methods: {
    goBack() {
      this.$router.replace({
        name: 'featureSet',
        query: {
          name: 'featureSet',
          active: this.$route.query.active,
        },
      })
    },
    async getList() {
      const params = {
        page: this.page,
        limit: this.limit,
        sort: 0,
      }
      this.$api.getPointsExchange(params).then(res => {
        if (res.code == 1) {
          this.list = res.data.list || [];
          this.total = res.data.count || 0;
        } else {
          this.list = [];
          this.total = 0;
        }
      })
    },
    pageChange(e) {
      this.page = e;
      this.getList();
    },
    confirmPopDialog(data) {
      this.del(data.data);
      this.closePopDialog();
    },
    closePopDialog(data) {
      this.showDialog = false;
    },
    openDialog(item) {
      this.dialogTitle = "提示";
      this.dialogMsg = `是否确认删除？`;
      this.dialogType = '4';
      this.dialogData = item;
      this.showDialog = true;
    },
    async del(item) {
      let res = await this.$api.deletePointsExchange(item.id);
      if (res.code == 1) {
        this.$toast('删除成功');
        await this.getList();
      }
    },
    jumpToDetail(type, item) {
      if (type == 1) {
        this.$router.push({
          name: 'exchange-scheme-add',
          query: {
            name: 'exchange-scheme-add',
            active: 13
          }
        })
      } else if (type == 2) {
        this.$router.push({
          name: 'exchange-scheme-add',
          query: {
            name: 'exchange-scheme-edit',
            active: 13,
            id: item.id,
          }
        })
      }
    },
  }
}
</script>

<style lang="less" scoped>
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bottom-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 0.7rem 0.7rem rgba(229, 229, 229, 0.26);
  background: #fff;
  padding: 0 3rem;
  height: 10rem;

  .r-s-add-btn {
    font-size: 2.4rem;
    width: 42rem;
    height: 7rem;
    border-radius: 0.8rem;
    .van-icon {
      margin-right: 1rem;
    }
  }
}

// /deep/.van-pagination__item--active {
//     color: #fff !important;
//     background-color: #1588F5 !important;
// }
// /deep/.van-pagination__item {
//     color: #1588F5;
// }
.list_title {
  height: 8rem;
  background: #f8f8f8;
  font-size: 2.6rem;
  font-weight: 400;
  color: #333333;
  display: grid;
  grid-template-columns: repeat(6, 16.66%);
  align-items: center;
  margin-top: 1.5rem;
}

.box_index {
  // height: 40rem;
  flex: 1;
  overflow-y: scroll;
}

.box_index::-webkit-scrollbar {
  display: none;
}

.list_index {
  height: 8.5rem;
  font-size: 2.6rem;
  font-weight: 400;
  color: #333333;
  display: grid;
  grid-template-columns: repeat(6, 16.66%);
  align-items: center;
  border-bottom: 0.1rem solid #e5e5e5;
  background: #fff;
}

.popups {
  width: 102.9rem;
  height: 48.7rem;
  background: #ffffff;
  text-align: -webkit-center;

  .system_title {
    display: flex;
    align-items: center;
    //justify-content: flex-end;
    //margin: 0 4.6rem 3.1rem 4.6rem;
    height: 12.5rem;
    border-bottom: 0.1rem solid #e5e5e5;
    font-size: 3.6rem;
    font-weight: 400;
    color: #333333;
    position: relative;

    >:first-child {
      flex: 1;
    }

    img {
      width: 4.3rem;
      position: absolute;
      right: 4rem;
      // height: 4.3rem;
      // text-align: end;
      //margin-left: 29.4rem;
    }
  }

  .detail {
    margin: 10rem 17.6rem 8.5rem 18rem;
    text-align: left;
    font-size: 2.8rem;
    font-weight: bold;
    color: #333333;
    line-height: 4rem;

    input {
      width: 62.6rem;
      height: 7.1rem;
      background: #f8f8f8;
      border-radius: 0.8rem;
      margin: 0 0 2rem 2rem;
      padding-left: 6.3rem;
      font-size: 2.6rem;
      font-weight: 400;
      color: #666666;
      line-height: 3.7rem;
    }

    .detail_context {
      font-size: 3.6rem;
      color: #fe5600;
    }
  }

  .but {
    width: 27rem;
    height: 7rem;
    background: linear-gradient(270deg, #1588F5 0%, #1588F5 100%);
    font-size: 2.8rem;
    font-weight: 500;
    color: #ffffff;
    line-height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.van-button--normal {
  font-size: 2.4rem;
}
</style>
